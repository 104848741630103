import { api } from '@/main'
import { AiAuditSLI, AiKind, AiRates } from '@/api/audit_sli'
import { AiProcedure } from '@/api/procedures'
// import { AiUnit } from '@/api/units'
import { useUsersStore } from '@/stores/users/users'

interface SLIResponse {
  title: string,
  columns: number
  template: string[]
}

interface AuditSli {
  [index: string]: AiAuditSLI
}

// interface DatesArray {
//   [index: string]: number
// }

// const filterOutUnitsCountByDate = (units: AiUnit[]): number[] => {
//   const datesArray: DatesArray = {}
//   for (const unit of units) {
//     const { created } = unit
//     const assemblyDate = new Date(created as string)
//     const day = String(assemblyDate.getDate())

//     const isGivenDayStored = Object.keys(datesArray).includes(day as string)
//     if (isGivenDayStored) {
//       datesArray[day] += 1
//     } else {
//       datesArray[day] = 1
//     }
//   }

//   return Object.values(datesArray)
// }

const buildSLIDataTemplate = (auditSlis: AuditSli/*, units: AiUnit[] */): SLIResponse[] => {
  if (typeof auditSlis !== 'object') return []

  const sliKeys = Object.keys(auditSlis)
  if (!sliKeys.length) return []

  // const unitsCountByDate = filterOutUnitsCountByDate(units)

  const headTemplate = ['']
  const unitsAuditedTemplate = ['Number of units audited']
  const deviationsWorkedTemplate = ['Number of audited units that have worker deviations']

  const missingF1Score = ['F1 Score']
  const missingTruePositiveTemplate = ['True-Positive Rate']
  const missingTrueNegativeTemplate = ['True-Negative Rate']
  const missingFalsePositiveTemplate = ['False-Positive Rate']
  const missingFalseNegativeTemplate = ['False-Negative Rate']

  const outOforderF1Score = ['F1 Score']
  const outOfOrderTruePositiveTemplate = ['True-Positive Rate']
  const outOfOrderTrueNegativeTemplate = ['True-Negative Rate']
  const outOfOrderFalsePositiveTemplate = ['False-Positive Rate']
  const outOfOrderFalseNegativeTemplate = ['False-Negative Rate']

  const columns = sliKeys.length + 1
  const sliData = [{
    title: 'General Metrics',
    columns,
    template: [],
  }, {
    title: 'Missing Deviations',
    columns,
    template: [],
  }, {
    title: 'Out of Order Deviations',
    columns,
    template: [],
  }]

  const isValueFinite = (value: number): boolean => Number.isFinite(value)
  const prepareRateForDisplay = (value: number): string => `${(value * 100).toFixed(2)}%`
  const updateValueIfUndefined = (value: string): string => value === 'undefined' || value === 'NaN' ? 'N/A' : value

  for (const sliKey of sliKeys) {
    const { numberOfAudits, numberOfWorkerDeviations, presence, order } = auditSlis[sliKey]

    headTemplate.push(sliKey)

    unitsAuditedTemplate.push(updateValueIfUndefined(String(numberOfAudits)))
    deviationsWorkedTemplate.push(updateValueIfUndefined(String(numberOfWorkerDeviations)))

    const { f1Score: presenceF1Score, rates: presenceRates } = presence as AiKind
    const presenceF1ScoreValue = String(presenceF1Score) === 'undefined' || String(presenceF1Score) === 'NaN' ? 'N/A' : (presenceF1Score as number).toFixed(1)
    missingF1Score.push(presenceF1ScoreValue as string)

    const missingTruePositiveRate = (presenceRates as AiRates).truePositivesRate as number
    const missingTruePositiveValue = isValueFinite(missingTruePositiveRate) ? prepareRateForDisplay(missingTruePositiveRate) : updateValueIfUndefined(String(missingTruePositiveRate))
    missingTruePositiveTemplate.push(missingTruePositiveValue)

    const missingTrueNegativeRate = (presenceRates as AiRates).trueNegativesRate as number
    const missingTrueNegativeValue = isValueFinite(missingTrueNegativeRate) ? prepareRateForDisplay(missingTrueNegativeRate) : updateValueIfUndefined(String(missingTrueNegativeRate))
    missingTrueNegativeTemplate.push(missingTrueNegativeValue)

    const missingFalsePositiveRate = (presenceRates as AiRates).falsePositivesRate as number
    const missingFalsePositiveValue = isValueFinite(missingFalsePositiveRate) ? prepareRateForDisplay(missingFalsePositiveRate) : updateValueIfUndefined(String(missingFalsePositiveRate))
    missingFalsePositiveTemplate.push(missingFalsePositiveValue)

    const missingFalseNegativeRate = (presenceRates as AiRates).falseNegativesRate as number
    const missingFalseNegativeValue = isValueFinite(missingFalseNegativeRate) ? prepareRateForDisplay(missingFalseNegativeRate) : updateValueIfUndefined(String(missingFalseNegativeRate))
    missingFalseNegativeTemplate.push(missingFalseNegativeValue)

    const { f1Score: orderF1Score, rates: orderRates } = order as AiKind
    const orderF1ScoreValue = String(orderF1Score) === 'undefined' || String(orderF1Score) === 'NaN' ? 'N/A' : (orderF1Score as number).toFixed(1)
    outOforderF1Score.push(orderF1ScoreValue as string)

    const outOfOrderTruePositiveRate = (orderRates as AiRates).truePositivesRate as number
    const outOfOrderTruePositiveValue = isValueFinite(outOfOrderTruePositiveRate) ? prepareRateForDisplay(outOfOrderTruePositiveRate) : updateValueIfUndefined(String(outOfOrderTruePositiveRate))
    outOfOrderTruePositiveTemplate.push(outOfOrderTruePositiveValue)

    const outOfOrderTrueNegativeRate = (orderRates as AiRates).trueNegativesRate as number
    const outOfOrderTrueNegativeValue = isValueFinite(outOfOrderTrueNegativeRate) ? prepareRateForDisplay(outOfOrderTrueNegativeRate) : updateValueIfUndefined(String(outOfOrderTrueNegativeRate))
    outOfOrderTrueNegativeTemplate.push(outOfOrderTrueNegativeValue)

    const outOfOrderFalsePositiveRate = (orderRates as AiRates).falsePositivesRate as number
    const outOfOrderFalsePositiveValue = isValueFinite(outOfOrderFalsePositiveRate) ? prepareRateForDisplay(outOfOrderFalsePositiveRate) : updateValueIfUndefined(String(outOfOrderFalsePositiveRate))
    outOfOrderFalsePositiveTemplate.push(outOfOrderFalsePositiveValue)

    const outOfOrderFalseNegativeRate = (orderRates as AiRates).falseNegativesRate as number
    const outOfOrderFalseNegativeValue = isValueFinite(outOfOrderFalseNegativeRate) ? prepareRateForDisplay(outOfOrderFalseNegativeRate) : updateValueIfUndefined(String(outOfOrderFalseNegativeRate))
    outOfOrderFalseNegativeTemplate.push(outOfOrderFalseNegativeValue)
  }

  const generalTemplate = headTemplate
    .concat(unitsAuditedTemplate)
    .concat(deviationsWorkedTemplate)

  const presenceTemplate = headTemplate
    .concat(missingF1Score)
    .concat(missingTruePositiveTemplate)
    .concat(missingTrueNegativeTemplate)
    .concat(missingFalsePositiveTemplate)
    .concat(missingFalseNegativeTemplate)

  const orderTemplate = headTemplate
    .concat(outOforderF1Score)
    .concat(outOfOrderTruePositiveTemplate)
    .concat(outOfOrderTrueNegativeTemplate)
    .concat(outOfOrderFalsePositiveTemplate)
    .concat(outOfOrderFalseNegativeTemplate);

  (sliData[0] as SLIResponse).template = generalTemplate
  ;(sliData[1] as SLIResponse).template = presenceTemplate
  ;(sliData[2] as SLIResponse).template = orderTemplate

  return sliData
}

// const buildUnitsFilters = (procedureId: string) => {
//   const from = new Date(new Date().getTime())

//   from.setDate(new Date().getDate() - 1)
//   from.setHours(0)
//   from.setMinutes(0)
//   from.setSeconds(0)
//   from.setMilliseconds(0)

//   const unitsStartDate = Math.round(from.valueOf() / 1000)

//   const to = new Date(new Date().getTime())

//   to.setHours(23)
//   to.setMinutes(59)
//   to.setSeconds(59)
//   to.setMilliseconds(999)

//   const unitsEndDate = Math.round(to.valueOf() / 1000)

//   return JSON.stringify({
//     $and: [
//       { 'created.seconds': { $gte: unitsStartDate } },
//       { 'created.seconds': { $lte: unitsEndDate } },
//       { procedure: procedureId },
//     ],
//   })
// }

export const loadSLIData = async (procedureId: string, macrostepId: string): Promise<SLIResponse[]> => {
  try {
    const { userGroup } = useUsersStore()

    const { data: procedure } = await api.procedures.proceduresGetProcedure({
      procedure: procedureId,
    })

    const { internalProcedureName } = procedure
    const { data: { procedures: internalProcedures } } = await api.procedures.proceduresListProcedures({
      pageSize: -1,
      filter: JSON.stringify({
        internalProcedureName,
        manufacturer: { $in: userGroup },
      }),
    })

    const proceduresIds = (internalProcedures as AiProcedure[]).map((internalProcedure) => (internalProcedure.name as string).replace('procedures/', ''))

    try {
      const { data: { auditSlis } } = await api.slis.auditSLIsListAuditSLIs({
        macrostepId,
        body: {
          procedures: proceduresIds,
        },
      })

      const sliTemplate = buildSLIDataTemplate(auditSlis as AuditSli/*, units as AiUnit[] */)
      return sliTemplate
    } catch (error) {
      return []
    }
  } catch (error) {
    console.log(error)
    return []
  }
}
